import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TitleTr";
import Features from "pages/tr/FeaturePluto";
import MainFeature from "pages/tr/MainFeature.js";
import Pricing from "pages/tr/PricingPersonal.js";
import PricingPro from 'pages/tr/PricingProfessional.js';
import Testimonial from "pages/tr/Testimonial.js";
import FAQ from "pages/tr/FAQ";
import Footer from "pages/tr/Footer";



export default () => {

  const myStyle={
    backgroundImage: "url(https://images.pexels.com/photos/1694000/pexels-photo-1694000.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)",
    minHeight:'100vh',
    
    zIndex:-1,
    
  };

  return (
    <div style={myStyle}>
    <AnimationRevealPage >
      <Hero />
      <Features />
      <Pricing />
      <PricingPro />
      <MainFeature 
        subheading="Her üründe"
        heading="Yeni bir tasarım geliştiriyoruz"
        description=""
      
        buttonRounded={false}
      />
      <MainFeature 
        subheading="Markanız için"
        heading="Eşsiz bir tarzı garanti ediyoruz"
        description=""
  
        buttonRounded={false}
        textOnLeft={false}
      />
      <Testimonial />
      <FAQ />
      <Footer />
    </AnimationRevealPage>
    </div>
  );
}
