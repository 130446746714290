import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TitleEn";
import Features from "pages/en/FeaturePluto";
import MainFeature from "pages/en/MainFeature.js";
import Pricing from "pages/en/PricingPersonal.js";
import PricingPro from 'pages/en/PricingProfessional.js';
import Testimonial from "pages/en/Testimonial.js";
import FAQ from "pages/en/FAQ";
import Footer from "pages/en/Footer";



export default () => {

  const myStyle={
    backgroundImage: "url(https://images.pexels.com/photos/1694000/pexels-photo-1694000.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)",
    minHeight:'100vh',
    
    zIndex:-1,
    
  };

  return (
    <div style={myStyle}>
    <AnimationRevealPage >
      <Hero />
      <Features />
      <Pricing />
      <PricingPro />
      <MainFeature 
        subheading="We build"
        heading="A new design for every product"
        description=""
      
        buttonRounded={false}
      />
      <MainFeature 
        subheading="We Guarantee"
        heading="A matchless style for your brand"
        description=""
  
        buttonRounded={false}
        textOnLeft={false}
      />
      <Testimonial />
      <FAQ />
      <Footer />
    </AnimationRevealPage>
    </div>
  );
}
